@import "src/scss/module";

.banner {
  &__header {
    background-image: url("/assets/banners/home/header_bg.png");
    background-size: cover;
    border: 1px solid #d2d1d1;
    text-align: center;
    min-height: 165px;
  }

  &__siteSelector {
    border: 1px solid #d2d1d1;
    min-height: 315px;

    :global {
      .dropdown-center {
        width: 100%;
        max-width: 400px;

        .dropdown-toggle {
          background-color: #F4FAFF;
          border-color: #F4FAFF;
          font-size: 18px;
          display: block;
          width: 100%;
          padding-top: 8px;
          padding-bottom: 8px;

          &::after {
            border-top: 0.3em solid $primary;
            border-right: 0.3em solid transparent;
            border-bottom: 0;
            border-left: 0.3em solid transparent;
            margin-left: 1rem;
          }
        }

        .dropdown-menu {
          background-color: #F4FAFF;
          border-color: #F4FAFF;
          margin-top: -62px;
          border-radius: 0;

          @include media-breakpoint-up(sm) {
            width: 140%;
          }
        }

        .dropdown-item {
          font-size: 18px;
          font-weight: 500;
          text-align: center;

          &:hover, &:active, &:focus {
            color: $primary;
            background-color: transparent !important;
          }
        }
      }
    }
  }

  &__uploadCV {
    border: 1px solid #d2d1d1;
    min-height: 315px;

    a {
      text-decoration: none;
      cursor: pointer;
    }
  }

  &__closeForm {
    position: absolute;
    top: 0;
    right: 0;
    margin: 1rem;
    cursor: pointer;
  }

  &__uploadForm {
    position: relative;
    background-color: $secondary;
    color: $white;
    min-height: 315px;

    [ref="buttonMessageContainer"] {
      display: none;
    }

    :global {
      .form-radio {
        display: flex;
        flex-wrap: wrap;
        gap: .75em
      }

      .form-check-label {
        font-weight: 300;
        font-size: 17px;
      }

      .form-text {
        color: $white !important;
        font-size: small;
      }

      .formio-component-submit {
        .arrow-right {
          width: 18px;
          height: 15px;
          background-image: url("/assets/arrow_right_light.svg");
          background-size: cover;
        }
      }
    }
  }
}