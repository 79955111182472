@import "src/scss/module";

.tabList {
  background-image: url("/assets/banners/bg.png");
  background-size: cover;

  // Accordion mode

  :global {

    .accordion {

      &-item {
        border: 0;
        margin-bottom: $spacer;
        background-color: transparent;
      }

      &-button {
        display: flex;
        justify-content: space-between;
        font-weight: bold;
        border-radius: 0 !important;
        border-left: 5px solid;
        box-shadow: none;

        &[aria-expanded="true"] {
          background-color: $secondary;
          color: $white;
        }

        &:after {
          display: none;
        }

        i {
          width: 35px;
          height: 35px;
          border-radius: 100px;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            width: 10px;
            height: 10px;

            @include media-breakpoint-down(md) {
              transform: rotate(90deg);
            }
          }
        }
      }

      &-body {
        background-color: $secondary;
        color: $white;
      }
    }

    .tab-content {
      display: flex;

      .tab-pane.active {
        display: flex;
      }
    }
  }

  // Tab Mode

  &__nav {
    display: flex;
    flex-direction: column;
    gap: $spacer * .75;
    height: 100%;

    @include media-breakpoint-between(md, xl) {
      flex-direction: row;
    }

    @include media-breakpoint-up(md) {
      margin-left: -1rem;
    }
  }

  &__navLink {
    background-color: rgba($white, .5);
    border-radius: 0 !important;
    border-left: 5px solid;
    color: $dark;
    font-weight: bold;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 1.1;

    &:hover {
      color: $secondary;
    }

    i {
      width: 35px;
      height: 35px;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 10px;
        height: 10px;
      }
    }
  }

  // Variants

  @each $name, $color in $brand-colors {

    &[data-variant="#{$name}"] {
      i {
        background-color: $color;
      }

      :global {
        .accordion {
          &-button {
            border-color: $color;
          }
        }
      }
    }

    &[data-variant="#{$name}"] &__navLink {
      border-color: $color;
      i {
        background-color: $color;
      }
      [role="tabpanel"] > * {
        background-color: $color;
      }
      &[class*=active] {
        background-color: $color !important;
        border-color: $color;
      }
    }
    &[data-variant="#{$name}"] [role="tabpanel"] > * {
      background-color: $color;
    }

    @if $name == 'secondary' {
      &[data-variant="#{$name}"] {
        :global {
          .accordion {
            &-button {
              border-color: $primary;
            }
          }
        }
      }

      &[data-variant="#{$name}"] &__navLink {
        border-color: $primary;
      }
    }
  }


  // Multicolor

  &[data-variant="multicolor"] {

    :global {
      #tabList-tab-0 {
        border-color: $quinary;
      }

      #tabList-tab-0 i,
      #tabList-tab-0.active,
      #tabList-tabpane-0 {
        background-color: $quinary;
      }

      #tabList-tab-1 {
        border-color: $quaternary;
      }

      #tabList-tab-1 i,
      #tabList-tab-1.active,
      #tabList-tabpane-1 {
        background-color: $quaternary;
      }

      #tabList-tab-2 {
        border-color: $tertiary;
      }

      #tabList-tab-2 i,
      #tabList-tab-2.active,
      #tabList-tabpane-2 {
        background-color: $tertiary;
      }
    }

  }
}