@import "src/scss/module";

.video {
  &__trigger {
    position: relative;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      scale: 1.025;
    }

    &Icon {
      content: '';
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100px;
      height: 100px;
      border-radius: 100%;
      z-index: 1;
      transition: 0.3s;

      svg {
        width: 25px;
        height: 25px;
      }
    }
  }

  &__children {
    background-color: $black;

    & > * {
      opacity: .65;
    }
  }

  @each $name, $color in $brand-colors {
    &[class*=variant-#{$name}] &__triggerIcon {
      background-color: $color;
    }
    &[class*=variant-#{$name}]:hover &__triggerIcon {
      background-color: $secondary;
    }
  }

  &__modal {
    overflow: hidden;

    [class*="modal-content"] {
      border: 0;
    }

    iframe {
      border: 0;
    }
  }
}
