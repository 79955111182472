@import "src/scss/module";

.SeriousAboutTalent {

  &__tag {
    position: absolute;
    overflow: visible;
    width: 0;
    left: 0;
    transform: translateY(-3rem);
    font-weight: $font-weight-bold;
    font-size: clamp(100px, 20vw, 250px);
    line-height: 0.8;
    pointer-events: none;
    overflow-wrap: normal;
    z-index: 0;
  }

  &__title {
    display: flex;
    margin-left: -0.25em;
    padding-left: 0.25em;
    border-left: 5px solid;

    @include media-breakpoint-up(sm) {
      margin-left: -0.75em;
      padding-left: 0.75em;
    }
  }

  &__content {

    p {
      line-height: 1.5;
    }

    ul {
      li:not(:last-child) {
        margin-bottom: 1em  !important;
      }
    }
  }

  @each $name, $color in $brand-colors {
    &[data-variant="#{$name}"] &__tag {
      color: rgba($color, 0.1);
    }
    &[data-variant="#{$name}"] &__title {
      border-color: $color
    }
    &[data-variant="#{$name}"] &__content {
      a {
        color: $color;
      }
    }
  }
}