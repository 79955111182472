@import "src/scss/module";

.tabs {

  &__content {
    background-color: $secondary;
    color: $white;
    display: flex;
    align-items: center;
  }

  &__svg__text {
    font-size: 128px;
    fill: #FC470E;
    font-family: DIN, sans-serif;
    font-weight: 700
  }
}